import React from "react"
import PropTypes from "prop-types"
import "./navigation.css"

const Navigation = () => {
  return (
    <div>
      <nav class="navigation">
        <ul>
          <li><a href="#comunicados">Comunicados</a></li>
          <li><a href="#musica">Música</a></li>
          <li><a href="#videos">Vídeos</a></li>
          <li><a href="#conciertos">Conciertos</a></li>
        </ul>
      </nav>
    </div>
  )
}

Navigation.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Navigation