import React from "react"

import Layout from "../components/layout"
import AlgaraEp from "../components/algaraEp"
import AlgaraCs from "../components/algaraCassette2020"
import AlgaraLp from "../components/algaraLp"
import ManualGuerrilla from "../components/manualGuerrillaPortada"
import Video from "../components/video"
import SEO from "../components/seo"
import Navigation from "../components/navigation"

const IndexPage = () => (
  <Layout>
    <SEO title="G.R.A." />
    <Navigation></Navigation>
    <div style={{ marginBottom: `1.45rem` }}>

    	<div class="block" id="intro">
    		<p>Algara o لغارة para joder a los de <span class="underlined">VOX</span>.</p>
    		<p>Dígase de un ataque rápido y hecho por sorpresa. En la edad media se aplicaba a la tropa de caballería que salía a correr y robar la tierra del enemigo.</p>
    		<p>Esto es música para el proletariado del s.XXI, explotado sirviendo copas, subcontratado y con las pupilas quemadas de pasar horas frente a la pantalla del ordenador.</p>
    	</div>

        <div class="block" id="comunicados">
            <h2 style={{
            color: `#F9DF16`,
            textDecoration: `none`,
            textTransform: `uppercase`,
            letterSpacing: `1em`
          }}>Comunicados</h2>
          <h3 style={{
            color: `#F9DF16`,
            textDecoration: `none`,
            textTransform: `uppercase`,
          }}>Manual para una guerrilla sonora</h3>
          <p>Algara presentamos nuestro primer documento escrito. En él reflexionamos sobre las tecnologías de la sonorización y presentamos una serie de propuestas técnicas enfocadas a la grabación sonora con pocos medios.</p>
          <p>Este documento se conforma de un archivo digital para ser impreso donde se puede consultar la parte teórica de la información y un vídeo con comunicado y demostración práctica.</p>
          <a href='https://grupoderesistenciaalgara.com/documentosPublicos/manual_guerrilla_sonora.pdf' target="__blank">CONSULTAR DOCUMENTO</a>
          <ManualGuerrilla />
          <Video videoSrcURL="https://www.youtube.com/embed/Eiymqfkhv64" videoTitle="Manual para una guerrilla sonora" width="100%" height="350px" />
        </div>
        
        <div class="block" id="videos">
          <h2 style={{
            color: `#F9DF16`,
            textDecoration: `none`,
            textTransform: `uppercase`,
            letterSpacing: `1em`
          }} >Videos</h2>
         <p>Expulsados, vídeo para la cinta promocional "Una cosa más sin sentido alguno usada para hacer rico al mismo de siempre".</p>
          <Video videoSrcURL="https://www.youtube.com/embed/q6DFylAQuKk" videoTitle="Algara - Expulsados" width="100%" height="350px" />
         </div>
        
        <div class="block" id="musica">
            <h2 style={{
            color: `#F9DF16`,
            textDecoration: `none`,
            textTransform: `uppercase`,
            letterSpacing: `1em`
          }} >Música</h2>
        <h3 style={{ marginTop: `1.45rem`, color: `#F9DF16`, textTransform: `uppercase` }}>*Enamorados del control total</h3>
        <p>Enamorados del Control Total, editado a través de La Vida Es Un Mus. Un EP de 4 canciones para los que te traen la comida a casa. 4 disparos dedicados a aquellos que doblan el lomo descargando camiones en eventos en los que nunca podrán entrar.</p>
        <a href='https://algara.bandcamp.com/album/enamorados-del-control-total' target='__blank'><AlgaraEp style={{ marginBottom: `1rem` }} /></a>

        <h3 style={{ marginTop: `1.45rem`, color: `#F9DF16`, textTransform: `uppercase` }}>*Una cosa más sin sentido alguno para hacer rico al mismo de siempre</h3>
        <p>Una cosa más sin sentido alguno usada para hacer rico al mismo de siempre es un cassette promocional del primer LP de Algara que ha salido en los sellos La Vida Es Un Mus (Londres), MDA (Barcelona) y Educación Cínica (Buenos Aires). Contiene los 4 temas del EP "Enamorados del Control Total" regrabados con banda y 4 temas nuevos.</p>
        <p>En los dos primeros sellos los beneficios son para el <a href="https://sindicathabitatgesantandreu.wordpress.com/" target="__blank">Sindicat d'Habitatge de Sant Andreu</a>. En el tercero para <a href="http://www.derechos.org/correpi/eng.html" target="__blank">CORREPI (Coordinadora Contra la Represión Policial)</a>.</p>
        <a href='https://mdadisquera.bandcamp.com/album/una-cosa-m-s-sin-sentido-alguno-usada-para-hacer-rico-al-mismo-de-siempre' target='__blank'><AlgaraCs /></a>

        <h3 style={{ marginTop: `1.45rem`, color: `#F9DF16`, textTransform: `uppercase` }}>*Absortos en el tedio eterno</h3>
        <p>Grabado en nuestro espacio okupado de confianza, el primer largo de Algara se llama "Absortos en el tedio eterno": doce temas de agitación contra todo, una oda a los himnos que más nos gustan de los 80s. Editado a través de La Vida Es Un Mus, contiene el manual para una Guerrilla Sonora impreso, un póster con las letras y una pegatina.</p>
        <a href='https://lavidaesunmus.bandcamp.com/album/absortos-en-el-tedio-eterno' target='__blank'><AlgaraLp /></a>
        </div>
	  	
        <div class="block" id="conciertos">
            <h2 style={{
            color: `#F9DF16`,
            textDecoration: `none`,
            textTransform: `uppercase`,
            letterSpacing: `1em`,
            marginTop: `1.5em`
          }} id="conciertos">Conciertos</h2>
        <h3 style={{ marginTop: `1.45rem`, color: `#F9DF16`, textTransform: `uppercase` }}>2020</h3>
        <ul>
            <li>24 de enero - Ateneu l'Harmonia, Barcelona. Con Plataforma, Sistema de Entretenimiento y Endora y Sus Vicios.</li>
            <li>25 de enero - 7º aniversario de Madrid Radical. Sala El Juglar, Madrid. Con Accidente.</li>
            <li>21 de febrero - Barcelona. Con Vëlcro, Inzendio y En la Stakada.</li>
            <li>27 de febrero - Sala Taro, Barcelona. Con Coàgul y Plataforma.</li>
        </ul>
        <h3 style={{ marginTop: `1.45rem`, color: `#F9DF16`, textTransform: `uppercase` }}>2021</h3>
        <ul>
            <li>27 de febrero - Ateneu l'Harmonia, Barcelona.</li>
            <li>1 de mayo - La Cinètika, Barcelona.</li>
            <li>12 de junio - Ruido por Colombia. ???, Barcelona. Con Tus Colegas, Vëlcro, Inanición, Bonestorm y Glossolalia.</li>
            <li>20 de agosto - Castell de Montjuïc, Barcelona. Con Futuro Terror.</li>
            <li>4 de septiembre - La Cinètika, Barcelona. Con Hatã y Baixa Permanent.</li>
            <li>24 de septiembre - Sendero, Arnedo.</li>
            <li>25 de septiembre - Lekunberri. Con Joni Txintxes, Mármol y Accidente.</li>
            <li>26 de septiembre - Txirbilenea, Bilbo. Con Hotza, Aresi y Gurs.</li>
            <li>15 de octubre - Presentación "Absortos en el tedio eterno". La Deskomunal, Barcelona. Con Hatã y Sistema de Entretenimiento.</li>
            <li>16 de octubre - Wurlitzer, Madrid. Con Las Morsas.</li>
            <li>31 de octubre - La Cinètika. Con Tus Colegas, Boys Kissing Boys y Pöls.</li>
            <li>6 de noviembre - Concert a la murada, Palma de Mallorca. Con Barrera, Sangre Fría y Brindis al Sol.</li>
            <li>13 de noviembre - Can Xic, Viladecans. Con Leonor SS.</li>
            <li>19 de noviembre - Punk al parc, Parc del Clot, Barcelona. Con Putxerazo, Zephyr Lake y Rotura.</li>
            <li>11 de diciembre - Festival Andalucía Über Alles. Sala Holländer, Sevilla. Con Podium, Finale, Interzona, Urbex y Cromo.</li>
        </ul>
        </div>
    </div>
  </Layout>
)

export default IndexPage
